import React, { useEffect, useMemo, useState } from 'react';
import {
	subscriptionsListener,
	ticketsListener,
	associatedCustomersListener,
	ticketsForSubCustomerListener,
	ordersListeners
} from '@ameroservices-platform/attraction-frontend/app/store/shared/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectModules } from '@ameroservices-platform/attraction-frontend/app/store/shared/frontendSlice';
import Typography from '@mui/material/Typography';
import { Paper, Grid, IconButton, Button } from '@mui/material';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import QRCode from 'qrcode';
import SubscriptionStatus from '@ameroservices-platform/shared/ui-components/status/SubscriptionStatus';
import Icon from '@mui/material/Icon';
import Hidden from '@mui/material/Hidden';
import AccountSubscriptionMoreSettings from '@ameroservices-platform/attraction-frontend/app/main/apps/content/cmsContent/cmsComponents/custom/account/tabs/AccountSubscriptionMoreSettings';
import AccountSubscriptionQR from '@ameroservices-platform/attraction-frontend/app/main/apps/content/cmsContent/cmsComponents/custom/account/tabs/AccountSubscriptionQR';
import subscriptionState from '@ameroservices-platform/shared/enums/subscriptionState';
import AccountSubscriptionUpsell from '@ameroservices-platform/attraction-frontend/app/main/apps/content/cmsContent/cmsComponents/custom/account/tabs/AccountSubscriptionUpsell';

export default function AccountTabSubsscriptions({ contentElementProps }) {
	const dispatch = useDispatch();
	const modules = useSelector(selectModules);
	const user = useSelector(({ auth }) => auth.user);
	const allSubscriptions = useSelector(({ shared }) => shared.user.subscriptions);
	const associatedCustomers = useSelector(({ shared }) => shared.user.associatedCustomers);
	const tickets = useSelector(({ shared }) => shared.user.tickets);
	const subCustomerTickets = useSelector(({ shared }) => shared.user.subCustomerTickets);
	const { t } = useTranslation();

	useEffect(() => {
		if (user?.uid) {
			const unsubSubCustomers = dispatch(associatedCustomersListener(user.uid));
			const unsubSubscriptions = dispatch(subscriptionsListener(user.uid));
			const unsubTickets = dispatch(ticketsListener(user.uid));
			return () => {
				unsubSubCustomers();
				unsubSubscriptions();
				unsubTickets();
			};
		}
		return () => {};
	}, [dispatch, user]);

	useEffect(() => {
		if (user?.uid && allSubscriptions.length > 0) {
			const unsubsOrders = allSubscriptions.map(s => dispatch(ordersListeners(user.uid, s.id)));
			return () => {
				unsubsOrders.forEach(unsub => unsub());
			};
		}
		return () => {};
	}, [dispatch, user, allSubscriptions]);

	useEffect(() => {
		if (associatedCustomers?.length > 0) {
			const unsubsTickets = associatedCustomers.map(subCustomer =>
				dispatch(ticketsForSubCustomerListener(subCustomer.id))
			);
			return () => {
				unsubsTickets.forEach(unsub => unsub());
			};
		}
		return () => {};
	}, [dispatch, associatedCustomers]);

	const subscriptions = useMemo(() => {
		if (!tickets || !allSubscriptions || (allSubscriptions.length <= 0 && tickets.length <= 0)) {
			return null;
		}
		const allSubCustomerTickets = Object.values(subCustomerTickets).flat();
		return allSubscriptions
			?.map(subscription => {
				const subscriptionTickets = tickets.filter(ticket => ticket.subscriptionUid === subscription.id);
				subscriptionTickets.push(
					...allSubCustomerTickets.filter(ticket => ticket.subscriptionUid === subscription.id)
				);
				const currentTicket = subscriptionTickets[0];
				return {
					...subscription,
					tickets: subscriptionTickets,
					currentTicket
				}; // FIXME: Change to current ticket
			})
			.filter(subscription =>
				[
					subscriptionState.ACTIVE,
					subscriptionState.TERMINATED,
					subscriptionState.PAYMENT,
					subscriptionState.RENEWAL,
					subscriptionState.RENEWAL_FAILED
				].includes(subscription.state)
			);
	}, [allSubscriptions, tickets, subCustomerTickets]);

	return (
		<>
			<Grid container spacing={2} className={'mb-48'}>
				<Grid item xs={12}>
					<Paper className={'py-24 px-2 sm:px-20'}>
						<div className={'flex items-center justify-between flex-col sm:flex-row'}>
							<Typography variant="h5">{t('ACCOUNT_TAB_SUBSCRIPTIONS')}</Typography>
							{contentElementProps?.eventGroupUid && (
								<AccountSubscriptionUpsell contentElementProps={contentElementProps} />
							)}
						</div>
					</Paper>
				</Grid>
				{subscriptions?.map(subscription => (
					<Grid item xs={12}>
						<Paper className={'py-24 px-20'}>
							<Grid container spacing={2}>
								<Grid item xs={3} sm={2} md={1}>
									<div className={'flex items-center justify-center w-full h-full'}>
										<AccountSubscriptionQR
											contentElementProps={contentElementProps}
											subscription={subscription}
										/>
									</div>
								</Grid>
								<Grid item xs={9} sm={6} md={7} className={'relative'}>
									<div className={'flex items-center gap-12 mb-8'}>
										<Typography variant="h6">{subscription.name}</Typography>
										<SubscriptionStatus statusKey={subscription.state} />
									</div>
									<Typography color="textSecondary">
										{subscription.currentTicket?.issuedTo?.firstname}{' '}
										{subscription.currentTicket?.issuedTo?.lastname}
										{subscription.currentTicket?.issuedTo?.phone
											? `, ${subscription.currentTicket?.issuedTo?.phone}`
											: ''}
									</Typography>
									<Typography color="textSecondary">
										{subscription ? t('CARD_NUM') : t('TICKET_NUM')}:{' '}
										{subscription.currentTicket?.ticketNumber}
									</Typography>
									<div className={'absolute top-0 right-0 sm:hidden'}>
										<AccountSubscriptionMoreSettings
											contentElementProps={contentElementProps}
											subscription={subscription}
										/>
									</div>
								</Grid>
								<Hidden smDown>
									<Grid item xs={12} sm={4}>
										<div className={'flex justify-end items-end -mt-16 -mr-6'}>
											<AccountSubscriptionMoreSettings
												contentElementProps={contentElementProps}
												subscription={subscription}
											/>
										</div>
										<div className={'flex items-end justify-end flex-col mt-16'}>
											<Typography color="textSecondary">
												{t('FROM')}:{' '}
												{moment(subscription.currentTicket?.event.start).format('DD-MM-YYYY')}
											</Typography>
											<Typography color="textSecondary">
												{t('TO')}:{' '}
												{moment(subscription.currentTicket?.event.end).format('DD-MM-YYYY')}
											</Typography>
										</div>
									</Grid>
								</Hidden>
							</Grid>
						</Paper>
					</Grid>
				))}
			</Grid>
		</>
	);
}
